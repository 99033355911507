// Breakpoints
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1054px;
$breakpoint-xl: 1254px;

@mixin media-phone {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin media-tablet {
  @media (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin media-desktop {
  @media (max-width: $breakpoint-lg) {
    @content;
  }
}

@mixin media-large-desktop {
  @media (max-width: $breakpoint-xl) {
    @content;
  }
}

@mixin useErrorBg {
  background-color: $error-color;
  color: $error-contrast-color;
}

@mixin useWarningBg {
  background-color: $warning-color;
  color: $warning-contrast-color;
}

@mixin useSuccessBg {
  background-color: $success-color;
  color: $success-contrast-color;
}

@mixin usePrimaryBg {
  background-color: $primary-color;
  color: $text-primary;
}

@mixin useSecondaryBg {
  background-color: $secondary-color;
  color: $text-primary;
}

@mixin usePaperBg {
  background-color: $background-paper;
  color: $text-primary;
}

@mixin useDefaultBg {
  background-color: $background-default;
  color: $text-primary;
}

@mixin lighen($percentage: 0%) {
  filter: brightness(calc(100%) + $percentage);
}

@mixin darken($percentage: 0%) {
  filter: brightness(calc(100%) - $percentage);
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none !important;
  }

  &::-webkit-scrollbar-track {
    display: none !important;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
  }
}

@mixin landscape {
  @media all and (max-width: $breakpoint-lg) and (orientation: landscape) {
    @content;
  }
}
