@import "@/styles/theme/main.scss";
.button {
  all: unset;
  box-sizing: border-box;
  border-radius: $border-radius;
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: $text-secondary;

  background-color: transparent;

  transition:
    background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  cursor: pointer;

  &__indicator {
    width: 18px;
    height: 18px;
    border-radius: $border-radius;
    background-color: $divider-color;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--active {
    color: $primary-color;

    .button__indicator {
      background-color: $card-color;
      color: $primary-color;
    }
  }

  &:hover {
    color: $primary-color;
  }

  @include media-tablet {
    font-size: 12px;
    font-weight: 400;
  }
}
