@import "@/styles/theme/main.scss";
.container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  &--padding {
    padding-inline: 24px;
    padding-top: 16px;

    @include media-tablet {
      padding-inline: 16px;
    }
  }
}

.skeleton {
  @include skeleton($secondary: true);
  width: 60px;
  height: 14px;
}
